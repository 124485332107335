.IndexPage {
    text-align: center;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.IndexPage-overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.IndexPage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 0;
}
.IndexPage-title {
    display: flex;
    flex-direction: column;
}
.IndexPage-container h1 {
    font-family: 'Newton';
    font-weight: 100;
    font-size: calc(70px + 2vmin);
    font-weight: 100;
    line-height: calc(70px + 2vmin);
    color: #fff;
    padding-bottom: 2rem;
    margin: 0;
}

.IndexPage-container h2 {
    font-family: 'Newton';
    font-size: calc(10px + 2vmin);
    color: #fff;
    margin: 0 0 2rem 0;
    padding: 0;
}

.IndexPage-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    color: #fff;
    z-index: 1;
}

.Button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.Button-container a, .Button-container .Bar {
    margin: 0 1rem;
}

.Bar {
    width: 1px;
    height: 20px;
    background-color: #fff;
}

.About-content-text {
    position: relative;
    margin-top: 2rem;
    height: 100px;
    overflow: hidden;
    background: rgba(83, 80, 80, 0.14)!important;
    transition: ease-in-out 0.3s;
}

.About-content-text:hover {
    cursor: pointer;
    scale: 1.008;
}

.About-content-text-full {
    height: 100%;
    margin-bottom: 1rem;
    padding-bottom: 3rem!important;
    background: rgba(22, 22, 22, 0.712)!important;
}

.About-content-text p {
    margin: 0;
}

.About-content-text .About-button {
    position: absolute;
    bottom: 0px;
    right: 50%;
    transform: translateX(50%);
    color: #fff;
    background-color: #080808;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 0;
    padding: calc(1px + 0.5vmin) calc(10px + 2vmin) 0;
    border-radius: calc(20px + 0.5vmin) calc(20px + 0.5vmin) 0 0;
    cursor: pointer;
    transition: all 0.3s;
    font-size: calc(2px + 1.5vmin);
    bottom: 0;
}

.About-content-text-full .About-button {
    background-color: transparent;
}

.About-content-text .About-button svg {
    color: #fff;
    width: 1.5rem;
    height: 1.5rem;
    transition: all 0.3s;
}

.About-content-text-full .About-button svg {
    color: #fff;
    width: 1.5rem;
    height: 1.5rem;
    rotate: 180deg;
}

.About-content-text .About-button:hover svg {
    color: #000;
}

.About-content-text-full .About-button:hover svg {
    color: #000;
}

.About-button:hover {
    background-color: aliceblue;
    color: #000;
    padding: calc(1px + 0.8vmin) calc(10px + 2vmin);
}

.Contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    color: #fff;
    z-index: 1;
}

.Contact-container svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    transition: all 0.3s;
}

.Contact-container svg:hover {
    color: #fff;
    cursor: pointer;
    scale: 1.1;
}

.Main-project-title {
    font-weight: 600;
    font-size: calc(8px + 1vmin);
    margin: 0 1rem;
}

@media screen and (max-width: 768px) {
    .IndexPage-text {
        padding: 0 1rem;
    }
    .Bar {
        display: none;
    }
    .Button-container {
        flex-direction: column;
    }
    .Button-container a, .Button-container .Bar {
        margin: 0.5rem 0;
    }
    .IndexPage-content {
        width: 90%;
    }
    .Main-project-title {
        margin: 1rem 0;
    }
    .About-button {
        font-size: calc(2px + 2vmin);
    }
    .About-content-text-full {
        padding-bottom: 1rem!important;
    }
    .About-content-text-full .About-button svg {
        width: 1rem;
        height: 1rem;
    }
    .About-content-text .About-button svg {
        width: 1rem;
        height: 1rem;
    }
}