.About {
    background: url("https://free4kwallpapers.com/uploads/originals/2020/07/17/dark-planet.-wallpaper_.jpg"), no-repeat center;
    background-size: cover;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
}

.About-container {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.About-container h1 {
    font-size: calc(20px + 3vmin);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 50px;
}

.About-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: calc(50px + 2vmin);
}

.About-content-text {
    width: 70%;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    padding: calc(1px + 2vmin);
}

.About-content-text p {
    text-align: justify;
    font-size: calc(8px + 1vmin);
    padding: 10px;
}

.About-content-text a {
    color: #a6a4a4;
    text-decoration: none;
}

.About-content-text a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .About-content-text {
        width: 80%;
    }
}