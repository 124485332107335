.Projects {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
}

.Projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Projects-container h1 {
    z-index: 1;
    font-family: 'Newton';
    font-weight: 100;
    font-size: calc(20px + 3vmin);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 50px;
}

.Glassmorphism-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: calc(50px + 2vmin);
    max-width: calc(30px + 150vmin);
}
