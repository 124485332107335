.navbar {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2%;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
}

.navbar:hover {
    cursor: pointer;
    transform: translateY(-2px) translateX(-50%);
}

.navbar-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #080808;
    border-radius: calc(20px + 2vmin) 0 calc(20px + 2vmin) 0;
    border: 1.5px solid #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.navbar-li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.navbar-a {
    text-decoration: none;
}

.navitem {
    width: calc(120px + 1vmin);
    margin: 0;
    padding: calc( 1vmin) calc(15px + 2vmin);
    font-size: calc(2px + 1.5vmin);
    color: #fff;
    text-decoration: none;
    background: transparent;
    transition: all 0.3s ease-in-out;
}

#itemstart {
    border-radius: calc(20px + 2vmin) 0 0 0;
}

#itemend {
    border-radius: 0 0 calc(20px + 2vmin) 0;
}

.navitem:hover {
    color: #181818;
    background: aliceblue;
}

.active .navitem{
    color: #282c34;
    background: aliceblue;
}

@media screen and (max-width: 768px) {
    .navbar-ul {
        height: calc(30px + 1vmin);
    }
    .navbar-li {
        width: calc(20px + 18vmin);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .navbar-a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .navitem {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 calc(5px + 2vmin);
        height: 100%;
        font-size: calc(2px + 2vmin);
    }
}

@media screen and (min-width: 2000px) {
    .navitem {
        font-size: calc(2px + 1.4vmin);
    }
}
