* {
    font-family: poppins, sans-serif;
}

body::-webkit-scrollbar {
    width: 12px;
}
body::-webkit-scrollbar-track {
    background: #ebebeb;
}
body::-webkit-scrollbar-thumb {
    background-color: #181818;
    border-radius: 20px;
    border: 3px solid #ebebeb;
}