.Skills {
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
}
.Skills-container {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.Skills-container h1 {
    z-index: 1;
    font-family: 'Newton';
    font-weight: 100;
    font-size: calc(20px + 3vmin);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 50px;
}

.Skills-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: calc(50px + 2vmin);
}

.Skills-card {
    background: rgba(83, 80, 80, 0.14)!important;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    width: 35%;
    margin: calc(1px + 2vmin);
    transition: all 0.2s ease-in-out;
    padding: calc(1px + 2vmin);
}

.Skills-card h2 {
    font-size: calc(8px + 2vmin);
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 0;
}

.Skills-card p {
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 5px 10px;
    background-color: #777;
    border-radius: 20px;
    font-size: calc(8px + 1vmin);
    color: #fff;
}

.Skills-card p img, .Skills-card p svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.Skills-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.Skills-item div {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .Skills-card {
        width: 80%;
    }
    .Skills-container h3 {
        font-size: 0.9rem;
    }
}

@media screen and (min-aspect-ratio: 21/9) {
    .Skills-content {
        width: 70%;
    }
}
