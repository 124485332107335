.btn {
    position: relative;
    padding: calc(1px + 1vmin) calc(30px + 2vmin);
    border-radius: calc(20px + 2vmin) 0 calc(20px + 2vmin) 0;
    background-color: transparent;
    color: #fafafa;
    border: solid 1.5px rgba(250, 250, 250, 0.9);
    overflow: hidden;
    cursor: pointer;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.btn:active {
    scale: 0.9;
}
.btn .btn-text {
    position: relative;
    font-size: calc(2px + 1.5vmin);
    z-index: 2;
}
.btn .fill-container {
    position: absolute;
    background-color: #080808;
    left: 0;
    top: 50%;
    width: 100%;
    padding-bottom: 100%;
    transform: translateY(-50%) rotate(180deg);
}
.btn .fill-container::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    transform: translateY(-100%);
}
.btn:hover {
    color: black;
    border-color: #fff;
    transform: translateY(-4px);
}
.btn:hover .fill-container {
    transform: translateY(-50%) rotate(0);
}
.btn:hover .fill-container::after {
    transform: translateY(0);
}

@media screen and (max-width: 768px) {
    .btn .btn-text {
        font-size: calc(2px + 2vmin);
    }
}

@media screen and (min-width: 2000px) {
    .btn .btn-text {
        font-size: calc(2px + 1.4vmin);
    }
}
