.Experiences {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
}

.Experiences-container {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Experiences-container h1 {
    z-index: 1;
    font-family: 'Newton';
    font-weight: 100;
    font-size: calc(20px + 3vmin);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 50px;
}

.timeline {
    margin-bottom: calc(50px + 2vmin);
}
.timeline:before {
    content: "";
    position: fixed;
    top: 150px;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    width: 4px;
    background-color: #fff;
}
.entries {
    width: calc(100% - 80px);
    max-width: 800px;
    margin: auto;
    position: relative;
    left: -5px;
}

@media screen and (max-width: 768px) {
    .timeline:before {
        left: 10%;
    }
}
